import React, { useEffect } from "react";
import './PrivacyPolicy.css'; // Ensure to import your CSS file

export default function PrivacyPolicycustom() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://app.termly.io/embed-policy.min.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="">
      <h1 className="">Legal Agreements and Policies</h1>
      <div className="policy-content">
        <div name="termly-embed" data-id="4a13c42d-d575-4f4a-8965-ce757b94e846"></div>
      </div>
    // </div>
  );
}

