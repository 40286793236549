import React, { useEffect } from "react";
import './EULA.css'; // Ensure to import your CSS file

export default function LegalAgreements() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://app.termly.io/embed-policy.min.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="container">
      <h1 className="page-header">Legal Agreements and Policies</h1>
      <div className="policy-content">
        <div name="termly-embed" data-id="38d987ae-45d7-438a-8727-ed8a03dd49f5"></div>
      </div>
    </div>
  );
}
