import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import axios from 'axios';
import PreLaunchInfo from './PreLaunchInfo';
import SignUpForm from './SignUpForm';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const moveToTop = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-50vh); /* Move the text to the top of the container */
  }
`;

const moveToTopSmallScreen = keyframes`
  from {
    transform: translateY(40vh);
  }
  to {
    transform: translateY(-7vh); /* Adjust for small screens to keep the logo visible */
  }
`;

const dotAnimation = keyframes`
  0%, 20% {
    opacity: 0;
  }
  40%, 100% {
    opacity: 1;
  }
`;

const LandingPageContainer = styled.div`
  padding: 20px;
  background: url('assets/bg.png') no-repeat center center;
  background-size: cover;
  min-height: 95vh; /* Ensures the height is at least 100vh */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
   @media (max-width: 768px) {
    min-height: 100vh; /* Ensures the height is at least 100vh */
  }
`;

const TextContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  width: 100%;
  animation: ${props => (props.animate ? moveToTop : 'none')} 2s forwards;
  animation-fill-mode: forwards; /* Ensures the animation stays in its end state */

  @media (max-width: 768px) {
    animation: ${props => (props.animate ? moveToTopSmallScreen : 'none')} 2s forwards;
    top: 9%;
  }
`;

const Title = styled.h1`
  font-size: 4rem;
  margin: 0;
  img {
    width: 100%;
    max-width: 330px; /* Adjust as needed */
  }
  @media (max-width: 768px) {
    img {
      width: 100%;
      max-width: 280px; /* Adjust as needed */
    }
  }
`;

const ComingSoon = styled.p`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 2rem;
  margin-top: 20px;
  color: white !important;
  opacity: 0;
  animation: ${fadeIn} 1s forwards;
  animation-delay: 2s; /* Delay fade-in until text has moved up */
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const Dot = styled.span`
  animation: ${dotAnimation} 1.5s infinite;
  &:nth-child(2) {
    animation-delay: 0.5s;
  }
  &:nth-child(3) {
    animation-delay: 1s;
  }
`;

const NewComponentsContainer = styled.div`
  display: ${props => (props.show ? 'flex' : 'none')};
  justify-content: center; /* Center the components horizontally */
  align-items: center; /* Center the components vertically */
  width: 100%;
  gap: 20px; /* Adjust this value to control the spacing between the containers */
  animation: ${fadeIn} 2s forwards;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Footer = styled.footer`
  width: 100%;
  text-align: center;
  padding: 10px 0;
  position: absolute;
  bottom: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  animation: ${fadeIn} 8s forwards;
`;


const FooterLinks = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 10px;
`;

const FooterLink = styled.a`
  color: #fff;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const CopyRight = styled.p`
  color: #fff;
  font-size: 0.8rem;
  margin: 0;
`;

const ConditionalDiv = styled.div`
  height: 0;
  @media (max-width: 768px) {
    height: 100px;
  }
`;

const LandingPage = () => {
  const [animate, setAnimate] = useState(false);
  const [showComingSoon, setShowComingSoon] = useState(false);
  const [showNewComponents, setShowNewComponents] = useState(false);
  const [target, setTarget] = useState(0); // Initialize with 0 and fetch the real count

  useEffect(() => {
    const fetchUserCount = async () => {
      try {
        const response = await axios.get('https://website-partylux-production.up.railway.app/v1/user-count');
        setTarget(1011 + response.data.count);
      } catch (error) {
        console.error('Error fetching user count:', error);
      }
    };

    fetchUserCount();

    const timer1 = setTimeout(() => {
      setAnimate(true);
    }, 1000);

    const timer2 = setTimeout(() => {
      setShowComingSoon(true);
    }, 2000);

    const timer3 = setTimeout(() => {
      setShowComingSoon(false);
      setShowNewComponents(true);
    }, 5000);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
    };
  }, []);

  const incrementCounter = () => {
    setTarget(prevCounter => prevCounter + 1);
  };

  return (
    <LandingPageContainer>
      <TextContainer animate={animate}>
        <Title>
          <img src="assets/logo.png" alt="logo" />
        </Title>
      </TextContainer>
      {showComingSoon && (
        <ComingSoon>
          Coming Soon<Dot>.</Dot><Dot>.</Dot><Dot>.</Dot>
        </ComingSoon>
      )}
      <NewComponentsContainer show={showNewComponents}>
        <PreLaunchInfo target={target} setTarget={setTarget} />
        <SignUpForm incrementCounter={incrementCounter} />
      </NewComponentsContainer>
      <ConditionalDiv ></ConditionalDiv>
      <Footer show={showNewComponents}>
        <FooterLinks>
          <FooterLink href="/terms-and-conditions" target="_blank">Terms & Conditions</FooterLink>
          <FooterLink href="/Privacy-Policy" target="_blank">Privacy Policy</FooterLink>
          <FooterLink href="/EULA" target="_blank">EULA</FooterLink>
        </FooterLinks>
        <CopyRight>© 2024 Party Lux. All rights reserved.</CopyRight>
      </Footer>
    </LandingPageContainer>
  );
};

export default LandingPage;
