import React from 'react';
import LandingPage from './components/LandingPage';
import styled from 'styled-components';
import AppRoutes from "./routes/Routes";


const AppContainer = styled.div`
  text-align: center;
  background-color: #0d0d0d;
  color: #fff;
  min-height: 100vh;
`;

function App() {
  return (
    // <AppContainer>
    //   <LandingPage />
    // </AppContainer>
    <div>
    <AppRoutes />
  </div>
  );
}

export default App;
