import React from "react";
import { Routes, Route } from "react-router-dom";
import LandingPage from "../components/LandingPage.js";
import TermsAndConditions from "../pages/TermsAndConditions.js";
import PrivacyPolicy from "../pages/PrivacyPolicy.js";
import EULA from "../pages/EULA.js";





const AppRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
    <Route path="/Privacy-Policy" element={<PrivacyPolicy />} />
        <Route path="/EULA" element={<EULA />} /> 

      </Routes>
    </>
  );
};

export default AppRoutes;
