import React, { useState } from 'react';
import styled from 'styled-components';
import { parsePhoneNumberFromString, isValidNumber } from 'libphonenumber-js';
import axios from 'axios';
import './PreLaunchForm.css';
import countryCodes from './countrycodes';

const Button = styled.button`
  background-color: ${props => (props.disabled ? 'gray' : '#58037D')};
  color: #fff;
  padding: 15px 20px;
  border: none;
  border-radius: 10px;
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
  margin-top: 15px;
  width: 100%;
  &:hover {
    background-color: ${props => (props.disabled ? 'gray' : '#5a005a')};
  }
`;

const PreLaunchForm = (props) => {

  const isPhoneNumber = false;
  const [formData, setFormData] = useState({
    firstName: '',
    phoneNumber: '',
    email: '',
    countryCode: '+973', // Default to Bahrain
    agreeToReceiveTexts: true // Default to true
  });
  const [errors, setErrors] = useState({
    firstName: '',
    phoneNumber: '',
    email: ''
  });
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(""); // Store error message
  const [isSubmitting, setIsSubmitting] = useState(false); // Track loading state

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
    setErrors({
      ...errors,
      [name]: '' // Clear error message on input change
    });
  };

  const validateForm = () => {
    let valid = true;
    let newErrors = {};

    if (!formData.firstName) {
      newErrors.firstName = 'First Name is required';
      valid = false;
    }

    if (isPhoneNumber) {
      if (!formData.countryCode) {
        newErrors.countryCode = 'Country Code is required';
        valid = false;
      }

      if (!formData.phoneNumber) {
        newErrors.phoneNumber = 'Phone Number is required';
        valid = false;
      } else {
        const phoneNumber = parsePhoneNumberFromString(formData.countryCode + formData.phoneNumber);
        if (!phoneNumber || !isValidNumber(phoneNumber.number, phoneNumber.country)) {
          newErrors.phoneNumber = 'Invalid phone number';
          valid = false;
        }
      }
    } else {
      if (!formData.email) {
        newErrors.email = 'Email is required';
        valid = false;
      } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
        newErrors.email = 'Invalid email address';
        valid = false;
      }
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setIsSubmitting(true); // Set loading state to true
      try {
        console.log("hellow")
        const response = await axios.post(
          'https://website-partylux-production.up.railway.app/v1/addNewMember',
          { ...formData, isPhoneNumber },
          {
            validateStatus: (status) => {
              // Resolve only if the status code is less than 500
              return status < 500;
            },
          }
        );
  
        switch (response.status) {
          case 200:
            console.log('Form Data Submitted:', formData);
            setFormData({
              firstName: '',
              phoneNumber: '',
              email: '',
              countryCode: formData.countryCode,
              agreeToReceiveTexts: formData.agreeToReceiveTexts
            });
            setShowSuccess(true);
            props.incrementCounter();
            setShowError(false);
            setTimeout(() => setShowSuccess(false), 3000);
            break;
  
          case 202:
          case 409:
            setErrorMessage(response.data.msg);
            setShowError(true);
            setShowSuccess(false);
            setTimeout(() => setShowError(false), 3000);
            break;
  
          case 400:
          case 201:
            setErrorMessage(response.data.error);
            setShowError(true);
            setShowSuccess(false);
            setTimeout(() => setShowError(false), 3000);
            break;
  
          default:
            setErrorMessage('An unexpected error occurred.');
            setShowError(true);
            setShowSuccess(false);
            setTimeout(() => setShowError(false), 3000);
            break;
        }
      } catch (error) {
        console.error('Error submitting form:', error);
        setErrorMessage("An error occurred. Please try again.");
        setShowError(true);
        setShowSuccess(false);
        setTimeout(() => setShowError(false), 3000);
      } finally {
        setIsSubmitting(false); // Set loading state to false
      }
    }
  };

  return (
    <div>
      {showSuccess && (
        <div className="alert alert-success" role="alert">
          You're in! Enrolled and ready to rock!
        </div>
      )}
      {showError && (
        <div className="alert alert-danger" role="alert">
          {errorMessage}
        </div>
      )}
      <div className="form-container">
        <h2>Pre-Launch</h2>
        <form className='form-div' onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="firstName">First Name</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              className={errors.firstName ? 'error-border' : ''}
              disabled={isSubmitting} // Disable input while submitting
            />
            {errors.firstName && <span className="error">{errors.firstName}</span>}
          </div>
          {isPhoneNumber ? (
  <div className="form-group-inline">
    <div className="form-group form-group-code">
      <label htmlFor="countryCode">Country Code</label>
      <select
        id="countryCode"
        name="countryCode"
        value={formData.countryCode}
        onChange={handleChange}
        disabled={isSubmitting} // Disable input while submitting
      >
        {countryCodes.map((country) => (
          <option key={country.code} value={country.code}>{country.name} ({country.code})</option>
        ))}
      </select>
    </div>
    <div className="form-group form-group-phone">
      <label htmlFor="phoneNumber">Phone Number</label>
      <input
        type="text"
        id="phoneNumber"
        name="phoneNumber"
        value={formData.phoneNumber}
        onChange={handleChange}
        className={errors.phoneNumber ? 'error-border' : ''}
        disabled={isSubmitting} // Disable input while submitting
      />
      {errors.phoneNumber && <span className="form-group-inline error">{errors.phoneNumber}</span>}
    </div>
  </div>
) :   <div className="form-group">
<label htmlFor="email">Email</label>
<input
  type="email"
  id="email"
  name="email"
  value={formData.email}
  onChange={handleChange}
  className={errors.email ? 'error-border' : ''}
  disabled={isSubmitting} // Disable input while submitting
/>
{errors.email && <span className="error">{errors.email}</span>}
</div>
}

           <div className="form-group">
            <label id='checkbox-id'>
              <p id='note-text'>By clicking submit, you agree to let Party Lux text you about launch updates </p>
            </label>
          </div>
          <Button className='Submit-button' type="submit" disabled={isSubmitting}>{isSubmitting ? 'Submitting...' : 'Submit'}</Button> {/* Use styled button */}
        </form>
      </div>
    </div>
  );
};

export default PreLaunchForm;
