import React from "react";
import EULA from "../components/EULA/EULA.js";

export default function EulaPage() {
  return (
    <div style={{   minHeight: '100vh', margin : "auto" ,"text-align": "center"}}>
      <EULA/>
    </div>
  );
}
