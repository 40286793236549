import React, { useState, useEffect } from 'react';
import './PreLaunchUsers.css?v=1.0.0';
import { FaInstagram, FaTiktok } from 'react-icons/fa';

const PreLaunchUsers = (props) => {
  const [counter, setCounter] = useState(props.target);
  const [previousTarget, setPreviousTarget] = useState(props.target);
  const duration = 7000; // Duration of the animation in milliseconds

  useEffect(() => {
    if (props.target !== previousTarget) {
      const startTime = Date.now();
      const endTime = startTime + duration;
      const startCount = counter;
      const endCount = props.target;

      const step = () => {
        const now = Date.now();
        const elapsedTime = now - startTime;
        const progress = Math.min(elapsedTime / duration, 1);
        const currentCount = Math.floor(progress * (endCount - startCount) + startCount);

        setCounter(currentCount);

        if (now < endTime) {
          requestAnimationFrame(step);
        }
      };

      requestAnimationFrame(step);

      setPreviousTarget(props.target);
    }
  }, [props.target, previousTarget, counter, duration]);

  const renderCounter = () => {
    const digits = counter.toString().split('');

    return digits.map((digit, index) => (
      <div key={index} className="number">
        {digit}
      </div>
    ));
  };

  return (
    <div className="users-container">
      <div className="number-container">
        {renderCounter()}
      </div>
      <p>Pre-launch users</p>
      <p>Be patient, good things come to those that stalk our social media! See you this summer!</p>
      <div className="social-icons">
        <a href="https://www.instagram.com/prty.lux?igsh=M3poZ3pscWxtbXhh&utm_source=qr" target="_blank" rel="noopener noreferrer">
          <FaInstagram size={30} style={{ color: 'white' }} />
        </a>
        <a href="https://www.tiktok.com/@prty.lux?_t=8n8rXmAYGpq&_r=1" target="_blank" rel="noopener noreferrer">
          <FaTiktok size={30} style={{ color: 'white' }} />
        </a>
      </div>
    </div>
  );
};

export default PreLaunchUsers;
